body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body{
  height: 100%;
}

* {
  -webkit-text-size-adjust: none;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.clearfix::after{
  content: "";
  display: block;
  clear: both;
}

.message-center{
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  height: 100px;
  font-size: 3em;
  text-transform: capitalize;

}

@media print {
  #header, #footer{
    display: none;
  }

  iframe{
    display: none
  }
}

