.header-subnav{
  padding: 20px 0 5px;
}
.header-subnav .subnav-result{
  float: left;
}

.header-subnav .subnav-card-type{
  float: left;
  margin-left: 40px;
}

.header-subnav .subnav-sort{
  float: right;
}

@media print {
  .header-subnav{
    display: none;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-subnav .subnav-result,
  .header-subnav .subnav-sort{
    float: unset;
    text-align: center; 
  }

  .header-subnav .selector-sort{
    border: 1px solid rgb(200,200,200);
    height: 43px;
    padding: 10px;
    width: 139px;
    margin: 7px 7px 10px;
  }
}


