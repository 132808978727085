.guideline header {
  padding: 40px;
  background-color: #6c757d;
  margin-bottom: 26px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.modal-guideline{
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.img-guideline{
  height: 100%;
  margin-bottom: 20px;
}

.container-fluid{
  margin-top: 40px;
}

.guideline .row{
  margin-bottom: 100px;
}

.explanation .badge{
  padding: 5px 7px;
  background-color: #17a2b8;
  color: white;
  border-radius: 24px;
  margin-right: 6px;
}

.guideline-title{
  text-align: center;
  margin-bottom: 20px;
}

.guideline-intro{
  text-align: center;
}

.explanation.text{
  padding-top: 50px;
}

.guideline .explanation{
  margin-bottom: 50px;
}

.modal-guideline .btn-close{
  position: fixed;
}

.modal-guideline-overlay {
  z-index: 10000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  bottom: 0px;
  background-color: rgba(100,100,100,0.5);
}

