#search_input {
  width: 70px;
}

.optionListContainer > ul {
  width: 225px;
}

.optionListContainer > ul > li.highlight {
  background-color: #0096fb;
}

.optionListContainer > ul > li {
  background-color: white;
}

.multiselect-container {
  max-width: 225px;
}

.header-nav {
  border-bottom: 1px solid rgb(200, 200, 200);
  padding: 15px;
  background: rgb(240, 240, 240);
  z-index: 110;
}
.header-nav .input-group-btn .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid rgb(206, 212, 217);
  border-right: none;
  background-color: rgb(250, 250, 250);
}

.header-nav .nav-search,
.header-nav .nav-filters {
  width: 30%;
  float: left;
}

.header-nav .nav-filters {
  width: 70%;
}

#error-box {
  display: none;
  margin: auto;
}

.alert-btn {
  padding: 20px 5px;
  width: 100%;
  color: white;
  border: none;
  background-color: rgb(255, 100, 100);
}

#error-box.show {
  display: block;
}

.nav-filters .nav-filter_season,
.nav-filters .nav-filter_cartegory {
  float: left;
  margin-left: 3px;
  margin-bottom: 10px;
}

.custom-text-field {
  display: none;
  float: left;
  margin-left: 5px;
}

.nav-filters .nav-filter_season,
.nav-filters .nav-filter_cartegory,
.custom-text-field,
.custom-text-wider-field {
  width: 16%;
}
.custom-brand {
  width: 14%;
}

.custom-evaluation {
  width: 8%;
}
.custom-price-adjustor {
  width: 10%;
}

.nav-filters .nav-filter_cartegory.price {
  width: 32%;
}

.nav-filters .nav-filter_cartegory .custom-select {
  max-width: 115px;
}

.nav-filters .nav-filter_cartegory.price {
  display: inline-block;
  margin-left: 3px;
}

.input-range .input-price-box {
  display: inline-block;
  width: 45%;
  position: relative;
}

.input-price-tag {
  position: absolute;
  left: 10px;
  padding-right: 7px;
  line-height: 36px;
  border-right: 1px solid rgb(205, 212, 219);
  color: rgb(120, 120, 120);
}

.input-range .input-price {
  padding-left: 37px;
}

.input-range-inbetween {
  margin: 0 1px;
  font-weight: bold;
  color: rgb(205, 212, 219);
}

.nav-views,
.nav-print {
  width: 40px;
  float: right;
  margin-left: 5px;
}

.nav-views,
.nav-print i {
  color: rgb(100, 100, 100);
}

.container {
  position: relative;
}

.btn-ui-controllers {
  float: left;
  display: none;
}

.btn-clear {
  float: right;
}

.custom-price-adjustor {
  float: right;
}

.btn-csv,
.btn-print {
  float: right;
  margin-left: 5px;
  border: 1px solid rgb(255, 255, 255);
  background: white;
}

.btn-text {
  text-transform: capitalize;
  color: rgb(100, 100, 100);
}

.header-nav .btn-mobile-search {
  display: none;
  background: rgb(250, 250, 250);
  border-radius: 0;
  border-bottom: 1px solid rgb(180, 180, 180);
}

.container-navi > select,
.container-navi > button {
  margin-left: 4px;
}

.btn-csv .badge,
.btn-print .badge {
  background-color: rgb(221, 132, 127);
  border-radius: 10px;
  color: white;
}

.btn-default {
  border: 1px solid rgb(220, 220, 220);
  background: white;
}

#search_input {
  height: 20px;
}

.search-wrapper {
  background-color: white;
  height: 38px;
}

.header-nav .showElem {
  display: block;
}

.custom-select.lang-type {
  width: 100px;
}

.custom-select.stock-type {
  width: 100px;
  float: right;
  display: none;
}

@media only screen and (max-width: 1150px) {
  .btn-clear,
  .btn-print {
    display: none;
  }

  .header-nav .showElem {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-nav .container {
    display: none;
  }

  .search-active .container {
    display: block;
    margin-top: 20px;
  }

  .header-nav {
    padding: 0;
  }
  .header-nav .btn-mobile-search {
    display: block;
    width: 100%;
  }
  .fa-search {
    margin-right: 5px;
  }
  .header-nav .nav-search {
    width: auto;
    float: unset;
  }

  .nav-filters .nav-filter_season {
    width: 99%;
  }

  .header-nav .nav-filters .nav-filter_cartegory {
    float: unset;
    margin: 10px auto;
  }
  .header-nav .nav-filters {
    float: unset;
    width: 100%;
  }

  .header-nav .nav-filters .nav-filter_cartegory.price {
    display: block;
  }
  .input-range .input-price {
    width: 100%;
  }
  .input-range .input-price-box {
    width: 47%;
  }

  .nav-filters .nav-filter_season,
  .nav-filters .nav-filter_cartegory,
  .custom-text-field,
  .custom-text-wider-field {
    width: 50%;
  }
  .nav-filters .nav-filter_cartegory.price {
    width: 100%;
  }
}
