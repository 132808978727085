.header{
	/* border-bottom: 1px solid rgb(200,200,200); */
	clear: both;
}

.sales{
	background-color: rgb(147,181,212);
	color: rgb(255,255,255);
}


.header .mobile{
	display: none;
}

.custom-typea{
}

.custom-typea .title-boxes{
	height:120px;
	padding-top: 20px;
	padding-bottom: 20px;
	width: 1150px;
	margin: auto;
}

.custom-typea .box-period{
	height: 80px;
	padding-top: 18px;
	padding-bottom: 18px; 
}

.custom-typea .title-box{
	height: 100%;
  display: inline-block;
  margin-right: 10px;
}
.custom-typea .number-year{
	font-size: 59px;
	font-family: serif;
}
.custom-typea .text-period{
	font-size: 25px;
	font-family: serif;
	line-height: 25px;
	font-weight: bold;
}
.custom-typea .text-title{
	font-size: 59px;
	font-family: serif;
}

.custom-typea .mobile .text-title{
	font-size: 2em;
  text-align: center;
}
.custom-typea .mobile.header-body{
	height: 65px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-typea .mobile .text-title{
  margin: 0;
}



.sales .header-body {
	padding: 40px 10px 30px;
	width: 100%;
}

.sales .title-box{
	width: 765px;
	margin: auto;
	position: relative;
	border-bottom: 2px solid rgb(255,255,255);
}

.sales .title-box_year{
	width: 20%;
	position: absolute;
	top: -28px;
	left: 5px;

}

.sales .title-box_text{
	width: 100%;
	text-align: center;
	font-family: serif;
}

.sales.christmas{
	background-color: rgb(100,20,20);
}

.christmas .title-box_text{
	width: 100%;
	float: auto;
	padding: 0;
	text-align: center;
	text-transform: uppercase;
}

.sales .title-box::after{
	content: "";
	clear: both;
	display: block;
}


.sales .title-box_year .number{
	font-size: 7.7em;
	color: rgb(255,255,255);

}

.sales .title-box_text .box-upper.text{
	letter-spacing: 3px;
}
.sales .title-box_text .box-bottom.text{
	letter-spacing: -1.5px;
}
.sales .title-box_text .text{
	font-size: 3em;
	color: rgb(255,255,255);
	text-transform: uppercase;
	list-style: none;
	height: 55px;
}

.cheer{
	background-color: rgb(209, 136, 130);
}

.newcheer.cheer{
	background-color: rgb(220,230,140);
}

.newcheer2.cheer{
	background-color: rgb(100,140,195);
}

.cheerclothes.cheer{
	background-color: rgb(160,190,230);
}

.cheer .title-box_text{
	color: rgb(255,255,255);
}

.newcheer.cheer .title-box_text{
	color: rgb(255,255,255);
}

.cheer .accent{
	color: rgb(148, 64, 60);
	font-family: Helvetica;
	letter-spacing: -6px;
	font-size: 1.05em;
	margin-left: -10px;
	font-family: 'Cabin', sans-serif;
}

.newcheer.cheer .accent{
	color: rgb(125,145,30);
	margin-left: 5px;
}

.newcheer2 .accent-primary{
	color: rgb(255,255,255);
}

.newcheer.cheer .accent.text{
	letter-spacing: 0.5px;
}

.newcheer2.cheer .accent,
.newcheer2.cheer .accent.text{
	color: rgb(30,90,150);
}

.cheerclothes.cheer .accent,
.cheerclothes.cheer .accent.text{
	color: rgb(30,90,150);
}

.cheer .header-body{
	width: 1110px;
	margin: auto;
	padding: 13px 0;
}

.cheer .title{
	float: left;
	font-size: 5.1em;
	text-transform: capitalize;
	font-weight: bold;
	font-family: 'Roboto', sans-serif;
}


.cheer .box-two-layers{
	float: left;
	margin: 14px 0 0 19px;
	list-style: none;
	padding: 0;
	font-size: 2em;
	line-height: 36px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
}

.cheer .contact-box{
	float: right;
	margin-top: 13px;
}

.cheer .contact-name {
	font-size: 1.2em;
	font-family: serif;
	letter-spacing: 0.5px;
}

.cheer .contact-table {
	font-size: 0.5em;
}


@media only screen and (max-width: 991px) {
	.header .desktop{
		display: none;
	}
	.header .mobile{
		display: block; 
	}
	.header .mobile .title{
		color: rgb(255,255,255);
		text-align: center;
		font-family: serif;
		font-size: 3em;
		margin: auto;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1150px) {

	.cheer .header-body{
		width: 90%;
	}

	.cheer .title{
		float: unset;
		font-size: 2.5rem;
		width: 90%;
		text-align: left;
		margin: auto;
	}

	.cheer .box-two-layers{
		float: unset;
		margin: auto;
		text-align: left;
		width: 90%;
		font-size: 1.5em;
		line-height: 26px;
	}

	.cheer .contact-box {
		float: unset;
		margin: 10px auto;
		width: 90%;
	}
}


.pstock{
	background-color: rgb(109, 136, 130);

}

.pstock .title-box_text{
	color: rgb(255,255,255);
}

.pstock .accent{
	color: rgb(148, 64, 60);
	font-family: Helvetica;
	letter-spacing: -6px;
	font-size: 1.05em;
	margin-left: -10px;
}

.pstock .header-body{
	width: 1110px;
	margin: auto;
	padding: 13px 0;
}

.pstock .title{
	float: left;
	font-size: 5.1em;
	text-transform: capitalize;
	font-weight: bold;
}

.pstock .box-two-layers{
	float: left;
	margin: 14px 0 0 19px;
	list-style: none;
	padding: 0;
	font-size: 2em;
	line-height: 36px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
}

.pstock .contact-box{
	float: right;
	margin-top: 13px;
}

.pstock .contact-name {
	font-size: 1.2em;
	font-family: serif;
	letter-spacing: 0.5px;
}

.pstock .contact-table {
	font-size: 0.5em;
}


@media only screen and (max-width: 991px) {
	.header .desktop{
		display: none;
	}
	.header .mobile{
		display: block; 
	}
	.header .mobile .title{
		color: rgb(255,255,255);
		text-align: center;
		font-family: serif;
		font-size: 3em;
		margin: auto;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1150px) {

	.pstock .header-body{
		width: 90%;
	}

	.pstock .title{
		float: unset;
		font-size: 2.5rem;
		width: 90%;
		text-align: left;
		margin: auto;
	}

	.pstock .box-two-layers{
		float: unset;
		margin: auto;
		text-align: left;
		width: 90%;
		font-size: 1.5em;
		line-height: 26px;
	}

	.pstock .contact-box {
		float: unset;
		margin: 10px auto;
		width: 90%;
	}
}


