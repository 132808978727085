.loading-guage.page-full{
  background-color: rgb(255,255,255);
  position: fixed;
  z-index: 101;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.opacity{
 opacity: 0.8;
}

.loading-guage .center{
  height: 300px;
  width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.btn-cancel{
  margin-top: 30px;
}


