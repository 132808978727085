.card-container {
  width: 100%;
  margin: auto;
}

.type3.card-container {
  width: 1150px;
}

.type3 {
  /* font-family: NotoSansJapaneseLight; */
}

.type3 .text-title-primary {
  /* font-family: NotoSansJapaneseMedium; */
}

.type3.card-container {
  padding: 0;
}

.type3.card-wrapper {
  height: 760px;
}

.type3 .card {
  height: 100%;
  border: 0;
}

.type3 .text-color-primary,
.type2 .text-color-primary {
  color: rgb(10, 110, 190) !important;
}

.type3 .text-box {
  -webkit-print-color-adjust: exact !important;
  height: 15px;
  width: 15px;
  margin: 7px 4px 2px 0px;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  background-color: rgb(80, 80, 80) !important;
  color: rgb(255, 255, 255);
}

.wrapper-rewriter {
  width: 100%;
  height: 30px;
}

.wrapper-rewriter .input-rewriter {
  float: left;
  width: 82%;
  border: 1px solid rgb(0, 123, 255);
  height: 100%;
  font-size: 11px;
  border-radius: 0;
}

.wrapper-rewriter .btn-rewriter {
  width: 18%;
  height: 100%;
  font-size: 12px;
  border-radius: 0;
  border-left: 0;
  border: 1px solid rgb(0, 123, 255);
  font-weight: bold;
  background-color: rgb(0, 123, 255);
  color: white;
  cursor: pointer;
  text-transform: capitalize;
}

.popup-textfield.popup-temp-imageUrl {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.popup-temp-imageUrl .bg-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.popup-temp-imageUrl .input-wrapper {
  position: absolute;
  top: 40%;
  z-index: 100;
  right: 0;
  left: 0;
  margin: auto;
  width: 92%;
}

.popup-temp-imageUrl .button-close {
  position: absolute;
  right: 0;
  bottom: 13px;
  font-size: 2em;
  z-index: 100;
  display: block;
  color: white;
  left: 0;
  margin: auto;
  text-align: center;
}

.editable-field-wrapper {
  width: 100%;
  height: 20px;
}

.card-img-top_type3 {
  position: relative;
  overflow: hidden;
  background-image: url("../../assets/loadingImg.gif");
  background-position: center;
  cursor: pointer;
  background-size: 100%;
  height: 570px;
  min-height: 570px;
  max-height: 570px;
  margin: 30px 30px 15px;
}

.card-img-top_type3 .card-img {
  max-width: none;
  position: absolute;
  margin: auto;
  left: -50%;
  right: -50%;
  height: 100%;
  width: auto;
}

.type3 .card-body {
  padding: 2px 0 0 48px;
  max-height: 170px;
  background: none;
  z-index: 100;
}

.type3 .row-primary {
  margin-top: 0px;
  margin-bottom: -5px;
}

.type3 .row-secondary {
  /* margin-top: -4px;
  margin-bottom: -4px; */
}

.type3 .row-primary .text-title {
  color: rgb(70, 70, 70);
  line-height: 26px;
  font-size: 15px;
  /* letter-spacing: -4px; */
}
.type3 .row-primary .text-title.text-color-emphasize {
  color: #ff8010;
}

.type3 {
  word-break: keep-all;
}

.type3 .text-optional {
  white-space: pre-wrap;
  z-index: 100;
}

.type3 .row-primary .text-price {
  color: rgb(70, 70, 70);
  white-space: pre-wrap;
  z-index: 100;
  line-height: 27px;
  font-size: 13px;
  padding-left: 10px;
}

.type3 .row-primary .text-optional-msg {
  color: rgb(70, 70, 70);
  line-height: 28px;
  font-size: 9px;
  padding-left: 5px;
}

.type3 .row-third .text-optional,
.type3 .row-secondary .text-optional {
  color: rgb(70, 70, 70);
  /* letter-spacing: -0.5px; */
  font-size: 10px;
  padding-left: 10px;
}

.type3 .row-third .text-optional {
  padding-left: 0;
}

.type3 .smaller-text-selected .text-box {
  font-size: 10px;
}

.type3 .smaller-text-selected .text-optional {
  font-size: 7.5px !important;
}

.type3 .row-secondary .text-optional:first-child {
  padding-left: 0;
}

.type3 .card-title {
  /* letter-spacing: -1.5px; */
  color: rgb(100, 100, 100);
  font-size: 12px;
  word-break: keep-all;
}

.type3 .border-bottom {
  padding: 0 0px 3px 3px;
  width: 400px;
  margin-right: auto;
  border-bottom: 1px solid rgb(100, 100, 100);
  margin-left: -5px;
}

.card-title {
  margin-bottom: auto;
  font-size: 14px;
  color: rgb(68, 124, 239);
}

.card.type2 .card-title {
  color: #343b57;
  margin-bottom: 10px;
}

.card-img-top {
  position: relative;
  background-image: url(/static/media/loadingImg.fd11930f.gif);
  background-position: 50%;
  background-size: 90px;
  cursor: pointer;
}

.card-img-top_type2 {
  min-height: auto;
  position: relative;
  overflow: hidden;
}

.card-img-top_type2 .card-img {
  height: 100%;
  object-fit: contain;
}

.card-text {
  margin: auto;
  font-size: 0.75em;
}

.card.type2 .margin2-left {
  margin-left: 10px;
}
.card.type2 .margin1-right {
  margin-right: 5px;
}

.card.type2 .size-common {
  font-size: 15px;
  word-break: keep-all;
}

.text-bold {
  font-weight: bold;
}

.type2 .text-bold {
  font-family: NotoSansJapaneseMedium;
  font-weight: normal;
}

.card-text .text {
  font-weight: bold;
}

.comida-wrapper .size-smaller > span {
  font-size: 12px;
}

.card.type2 .card-text .text {
  font-weight: normal;
}

.card-text_price {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 7px;
}

.list-horizontal .text,
.list-horizontal .label {
  margin: auto;
  text-align: center;
}

.list-horizontal .label {
  font-size: 0.7em;
}

.list-horizontal .text {
  font-size: 0.9em;
  font-weight: 500;
}

.list-horizontal {
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.list-horizontal .list {
  display: inline-block;
  list-style: none;
  width: 33%;
  padding: 2px 0;
}

/* .list-horizontal .list.middle{ */
/*   border-right: 1px solid rgb(200,200,200); */
/*   border-left: 1px solid rgb(200,200,200); */
/* } */

.list-horizontal {
  border-top: 1px dotted rgb(230, 230, 230);
}

.card-body {
  background: white;
  cursor: pointer;
}
.type2 span,
.type2 h5 {
  font-family: NotoSansJapaneseLight;
}

.type2 .card-body {
  padding: 10px;
}

.type2 .short-lineheight {
  line-height: 15px;
}

.btn-selected {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 50px;
  z-index: 10;
}

.btn-arrow-wrapper {
  position: absolute;
  top: 15px;
  right: 40px;
  width: 30px;
  display: none;
  z-index: 10;
}

.btn-arrow-wrapper .btn-arrow {
  margin: 2px 10px;
  background-color: #d8645e;
}

.btn-arrow-wrapper .btn-arrow > i {
  color: white;
}

.btn-break-point {
  left: 60px;
  border: 1px solid rgb(255, 0, 0);
}

.btn-smaller-text {
  left: 110px;
  border: 1px solid rgb(0, 255, 0);
}

.btn-selected .icon-btn {
  position: absolute;
  top: 6px;
  right: 0;
  left: 0;
  color: white;
  display: none;
}

.btn-selected.active-for-print,
.btn-break-point.breakpoint-active,
.btn-smaller-text.smaller-text-active {
  background-color: #d8645e;
  display: block;
}

.btn-selected.active-for-print .icon-btn,
.btn-break-point.breakpoint-active .icon-btn,
.btn-smaller-text.smaller-text-active .icon-btn {
  display: block;
}

.hover-show {
  display: none;
}

.card:hover .hover-show {
  display: block;
}

.hideCardElement {
  display: none;
}

.onlyShowOrderSize .list {
  display: none;
}
.onlyShowOrderSize .list.first,
.onlyShowOrderSize .list.middle {
  display: inline-block;
  margin-left: 30px;
}

.box-left {
  padding: 0px 8px;
  max-width: 94px;
}
.box-left,
.box-right {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .card-img-top {
    max-height: none;
    cursor: default;
  }
  .card-body {
    cursor: default;
  }
}

@media only screen and (max-width: 1150px) {
  .btn-selected {
    display: none !important;
  }
}
