@font-face {
  font-family: NotoSansJapaneseMedium;
  src: url("../../assets/Noto_Sans_JP/NotoSansJP-Medium.otf") format("opentype");
}

@font-face {
  font-family: NotoSansJapaneseLight;
  src: url("../../assets/Noto_Sans_JP/NotoSansJP-Light.otf") format("opentype");
}

@font-face {
  font-family: CrimsonProLight;
  src: url("../../assets/Crimson_Pro/static/CrimsonPro-Light.ttf")
    format("opentype");
}
.card-wrapper.cloned{
  visibility: hidden !important;
}

.block-maintenance {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
  z-index: 10000000;
  padding: 20%;
  text-align: center;
  font-size: 1.2em;
  line-height: 55px;
  display: none;
}

.block-maintenance.showMaintenance {
  display: block;
}

.print-header {
  display: none;
  /* width: 100%; */
  padding: 10px;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom: 1px solid rgb(200, 200, 200);
  margin-bottom: 30px;
}

.infinite-wrapper{
  padding-bottom: 100px;
}

.type3.infinite-wrapper {
  margin-left: -120px;
  margin-right: -120px;
}

.type3 .print-header {
  border: none;
}
.type3 .page-category {
  font-family: CrimsonProLight;
  padding-left: 50px;
}

.print-bottom{
  display: none;
  font-family: CrimsonProLight;
  color: rgb(80, 80, 80);
  margin-top: 80px;
}

.type3 .print-bottom.print-bottom-even {
  margin-left: 60px;
}

.type3 .print-bottom.print-bottom-odd {
  margin-left: 1130px;
}

.page-number {
  margin: auto;
}

.row {
  margin-bottom: 30px;
}

.card {
  height: 530px;
}

.no-gutter-card-deck > .card {
  margin: 0;
  border-radius: 0;
}

.no-gutter-card-deck {
  column-gap: 0;
  padding-right: 0;
  padding-left: 0;
}
.row {
  margin-bottom: 0;
  padding-right: 15px;
  padding-left: 15px;
}

.btn-load {
  width: 100%;
  padding: 15px;
  margin: 50px 0 30px;
  color: rgb(100, 100, 100);
  text-transform: capitalize;
  cursor: pointer;
}

.fixed .header-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.fixed .header-subnav {
  margin-top: 67px;
}

.btn-hint {
  position: fixed;
  bottom: 81px;
  right: 30px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  transition: 0.5s all ease 0s;
}

.icon-question {
  font-size: 1.5em;
  position: absolute;
  left: 13px;
  top: 7px;
}

.btn-hint .text {
  margin: auto;
  display: none;
  font-weight: bold;
}

.btn-hint:hover {
  width: 220px;
}

.btn-hint:hover .text {
  display: block;
}


@media print {
  
  .type3 .print-header {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .type3 .print-bottom {
    display: block;
    padding-top: 25px;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.22em;
  }
  .content-block {
    margin: auto;
  }

  .default-printStyle .card-img-top {
    min-height: 220px;
  }

  .btn-load,
  .header-nav {
    display: none;
  }

  .card-title {
    font-size: 0.8em;
  }

  .card-text {
    font-size: 0.6em;
  }

  .card-text_price {
    font-size: 1em;
  }

  .default-printStyle .print-header {
    display: block;
  }

  .card {
    height: 460px;
  }

  .type2 .card-text {
    font-family: "Helvetica";
  }

  .type2 .card-body {
    padding: 6px;
  }

  .type2 .short-lineheight {
    line-height: 14px;
  }

  .card.type2 .card-title {
    margin-bottom: 8px;
  }

  .headerless-printStyle .container {
    max-width: 1150px;
    padding: 0;
  }

  .headerless-printStyle .card-img-top {
    min-height: 320px;
  }

  .headerless-printStyle .card {
    height: 510px;
  }

  .print-page {
    page-break-inside: avoid;
    /* page-break-after: avoid; */
  }

  .card-wrapper.type2,
  .card-wrapper.default {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .headerless-printStyle .type2 .card-img-top {
    min-height: auto;
  }

  .card-wrapper.type2{
    height: 500px
  }

  .btn-hint,
  .loading-guage,
  .btn-selected {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .card {
    height: auto;
  }

  .card-body {
    padding-bottom: 8rem !important;
  }

  .btn-hint {
    display: none;
  }
}
