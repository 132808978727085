
.scroll {
  opacity: 0.6;
  background-color: rgb(100,100,100);
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 22px;
  border: none; 
  cursor: pointer;
}

.scroll:hover{
  opacity: 1;
}

.scroll:focus{
  outline:0;
}

.arrow-up {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  font-size: 1.5em;
}

@media print {
  .scroll{
    display: none;
  }
}
