.loading-page.page-full{
  background-color: rgb(255,255,255);
  position: fixed;
  z-index: 101;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.loading-page .center{
  height: 256px;
  width: 219px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}



