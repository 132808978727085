.home-header {
  padding: 10px;
  background-color: #6c757d;
}

.home-header span{
  display: inline-block;
}

.home-header-title{
  text-align: center;
  color: rgb(255,255,255);
}

.container.list{
  margin-top: 60px;
}

.list-catalogue {
  height: 400px;
  width: 330px;
  border-radius: 5px;
  color: white;
  text-align: center;
  padding-top: 100px;
  font-size: 2em;
  margin: 7px;
  background-color: rgb(150,150,150);
}

.list-catalogue.zakka{
  background-color: #17a2b8;
  
}

.list-catalogue.cheer{
  background-color: #bd2121;
  
}

.list-catalogue.newcheer{
  background-color: rgb(120,140,200);
  
}
